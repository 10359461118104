<template>
   <ObiText class="obi-alert alert" :class="componentClasses" v-bind="attrs" v-on="$listeners">
      <slot />
      <slot name="actions"></slot>
   </ObiText>
</template>

<script>
import { keys, omit } from 'lodash';

const alertColors = ['dark', 'light', 'secondary', 'primary', 'success', 'danger', 'info', 'warning'];

export default {
   name: 'ObiAlert',
   props: {
      ...alertColors.reduce((acc, color) => {
         acc[color] = {
            type: Boolean,
            default: false,
         };

         return acc;
      }, {}),
      justify: {
         type: String,
         default: 'between',
      },
   },
   computed: {
      componentClasses() {
         const classList = [];

         alertColors.forEach((color) => {
            if (!this[color]) return;

            classList.push(`alert-${color}`);
         });

         if (this.$slots.actions) {
            classList.push(`d-flex align-items-center justify-content-${this.$props.justify}`);
         }

         return classList;
      },
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
   },
};
</script>
<style lang="scss" scoped>
.obi-alert {
}
</style>
